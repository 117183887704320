import React, { useEffect, useState } from 'react';
import ArticleIcon from '@mui/icons-material/Article';
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import './featuredInfo.css';

export default function FeaturedInfo() {
  const [counts, setCounts] = useState({
    totalRequests: 0,
    pendingRequests: 0,
    declinedRequests: 0,
    completedRequests: 0,
  });
  
  const [technicianName, setTechnicianName] = useState('');
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error"
  });

  const fetchData = async () => {
    try {
      const response = await fetch('/api/getmaintenancerequestscount', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch maintenance request counts');
      }

      const data = await response.json();
      setCounts({
        totalRequests: data.totalRequests || 0,
        pendingRequests: data.pendingRequests || 0,
        declinedRequests: data.declinedRequests || 0,
        completedRequests: data.completedRequests || 0,
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.message || 'Failed to fetch data',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchTechnicianName = async () => {
    try {
      const response = await fetch('/api/getprofiledata', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch technician profile');
      }

      const data = await response.json();
      setTechnicianName(data.technicianName || 'User');
    } catch (error) {
      console.error('Error fetching technician profile:', error);
      setTechnicianName('User');
    }
  };

  useEffect(() => {
    fetchTechnicianName();
    fetchData();

    const refreshInterval = setInterval(() => {
      fetchData();
    }, 5 * 60 * 1000); // Refresh every 5 minutes

    return () => clearInterval(refreshInterval);
  }, []);

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  return (
    <div className="top">
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      <h1>Hello {technicianName}, Welcome!</h1>
      <h2 className="overview">Overview</h2>
      <div className="featured">
        <div className="featuredItem">
          <div className="featuredContent">
            <ArticleIcon className="icon first" />
            <div className="info">
              <span className="count">
                {loading ? "..." : counts.totalRequests}
              </span>
              <span className="label">Total Requests</span>
            </div>
          </div>
        </div>
        <div className="featuredItem">
          <div className="featuredContent">
            <ArticleIcon className="icon second" />
            <div className="info">
              <span className="count">
                {loading ? "..." : counts.pendingRequests}
              </span>
              <span className="label">Pending Requests</span>
            </div>
          </div>
        </div>
        <div className="featuredItem">
          <div className="featuredContent">
            <ArticleIcon className="icon third" />
            <div className="info">
              <span className="count">
                {loading ? "..." : counts.declinedRequests}
              </span>
              <span className="label">Declined Requests</span>
            </div>
          </div>
        </div>
        <div className="featuredItem">
          <div className="featuredContent">
            <ArticleIcon className="icon fourth" />
            <div className="info">
              <span className="count">
                {loading ? "..." : counts.completedRequests}
              </span>
              <span className="label">Completed Requests</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}