import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "./styleguide.css";
import "./LogIn.css";
import image from "../../img/process-cuate.png";
import image1 from "../../img/google-png-0-1.png";

// Base URL configuration


const loginTechnician = async (technicianEmail, password) => {
  try {
    const response = await fetch(`https://dhms.itservicedeskafrica.com/api/technicianlogin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "accept": "application/json"
      },
      body: JSON.stringify({
        technicianEmail,
        password,
      }),
    });

    const data = await response.json();

    if (data.status !== 200) {
      throw new Error(data.message || "Login failed");
    }

    // Store tokens from the new response structure
    if (data.Token?.access) {
      localStorage.setItem("accessToken", data.Token.access);
    }
    if (data.Token?.refresh) {
      localStorage.setItem("refreshToken", data.Token.refresh);
    }

    return data;
  } catch (error) {
    throw error;
  }
};

// Rest of the component remains exactly the same...
export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [eyeOff, setEyeOff] = useState(true);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const togglePassword = () => setEyeOff(!eyeOff);

  const handleSnackbarClose = () => setSnackbarOpen(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Basic validation
      if (!email || !password) {
        throw new Error("Email and Password are required!");
      }

      // Email format validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        throw new Error("Please enter a valid email address");
      }

      // Attempt login with the updated parameters
      const loginResponse = await loginTechnician(email, password);

      // Handle remember me
      if (rememberMe) {
        localStorage.setItem("rememberedEmail", email);
      } else {
        localStorage.removeItem("rememberedEmail");
      }

      // Store user email if available
      if (loginResponse.data?.technicianEmail) {
        localStorage.setItem("userEmail", loginResponse.data.technicianEmail);
      }

      setSnackbarMessage(loginResponse.message || "Login successful!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);

      // Short delay before navigation to show success message
      setTimeout(() => {
        navigate("/dashboard");
      }, 1000);

    } catch (error) {
      console.error("Login error:", error);
      setSnackbarMessage(error.message || "Login failed. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  // Load remembered email on component mount
  useEffect(() => {
    const rememberedEmail = localStorage.getItem("rememberedEmail");
    if (rememberedEmail) {
      setEmail(rememberedEmail);
      setRememberMe(true);
    }

    // Clear any existing tokens on mount
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  }, []);

  return (
    <div className="log-in">
      <div>
        <a href="https://itservicedeskafrica.com/" target="_blank" rel="noreferrer">
          <div className="ITSA-logo"></div>
        </a>
        <img className="process-cuate" src={image} alt="Process Cuate" />
      </div>
      <div>
        <div className="div">
          <div className="frame">
            <div className="text-wrapper">Welcome Back</div>
            <div className="text-wrapper-2">Log into your account</div>
          </div>
          <div className="frame-2">
            <form onSubmit={handleSubmit}>
              <div className="frame-3">
                <div className="frame-wrapper">
                  <div className="frame-4">
                    <label className="label" htmlFor="input-email">
                      Email
                    </label>
                    <div className="frame-5">
                      <input
                        className="input-text"
                        placeholder="Enter your Email"
                        type="email"
                        id="input-email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        disabled={loading}
                      />
                    </div>
                  </div>
                </div>
                <div className="frame-wrapper">
                  <div className="frame-4">
                    <label className="label" htmlFor="input-password">
                      Password
                    </label>
                    <div className="frame-5">
                      <input
                        className="input-text"
                        placeholder="Enter your password"
                        type={eyeOff ? "password" : "text"}
                        id="input-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        disabled={loading}
                      />
                      <IconButton
                        onClick={togglePassword}
                        disabled={loading}
                        style={{
                          position: "absolute",
                          right: "35px",
                          top: "49%",
                          transform: "translateY(-100%)",
                        }}
                      >
                        {eyeOff ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </div>
                  </div>
                </div>
                <div className="frame-6">
                  <input
                    type="checkbox"
                    id="remember-me"
                    className="checkbox"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                    disabled={loading}
                  />
                  <label htmlFor="remember-me" className="text-wrapper-3">
                    Remember me?
                  </label>
                </div>
              </div>
              <button className="button" type="submit" disabled={loading}>
                <div className="button-sample">
                  {loading ? "Logging in..." : "Log in"}
                </div>
              </button>
            </form>
          </div>
          <div className="overlap-group">
            <div className="social-login">
              <div className="sign-in-with-socials">
                <div className="text-wrapper-4">or</div>
              </div>
              <div className="frame-7">
                <img src={image1} alt="Google" className="google-png" />
                <div className="text-wrapper-5">Login with Google</div>
              </div>
              <p className="don-t-have-an">
                <span>Don't&nbsp;&nbsp;have an account?</span>
                <a href="/SignUp" className="text-wrapper-7">
                  Create account
                </a>
              </p>
            </div>
            <a href="/ResetPassword" className="text-wrapper-8">
              Forgot Password?
            </a>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}



// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import Snackbar from "@mui/material/Snackbar";
// import Alert from "@mui/material/Alert";
// import IconButton from "@mui/material/IconButton";
// import Visibility from "@mui/icons-material/Visibility";
// import VisibilityOff from "@mui/icons-material/VisibilityOff";
// import "./styleguide.css";
// import "./LogIn.css";
// import image from "../../img/process-cuate.png";
// import image1 from "../../img/google-png-0-1.png";

// const loginTechnician = async (technicianEmail, password) => {
//   // Simulated successful response for any email
//   return {
//     access: "dummy_access_token",
//     refresh: "dummy_refresh_token",
//     user: {
//       email: technicianEmail,
//       name: "Test User",
//       role: "technician"
//     }
//   };
// };

// export default function Login() {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [rememberMe, setRememberMe] = useState(false);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState("");
//   const [snackbarSeverity, setSnackbarSeverity] = useState("info");
//   const [eyeOff, setEyeOff] = useState(true);
//   const [loading, setLoading] = useState(false);

//   const navigate = useNavigate();

//   const togglePassword = () => setEyeOff(!eyeOff);

//   const handleSnackbarClose = () => setSnackbarOpen(false);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);

//     try {
//       // Basic validation
//       if (!email || !password) {
//         throw new Error("Email and Password are required!");
//       }

//       // Email format validation
//       const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//       if (!emailRegex.test(email)) {
//         throw new Error("Please enter a valid email address");
//       }

//       // Simulate login delay
//       await new Promise(resolve => setTimeout(resolve, 1000));
      
//       // Always successful login
//       const loginResponse = await loginTechnician(email, password);

//       // Handle remember me
//       if (rememberMe) {
//         localStorage.setItem("rememberedEmail", email);
//       } else {
//         localStorage.removeItem("rememberedEmail");
//       }

//       // Store user profile
//       localStorage.setItem("userProfile", JSON.stringify(loginResponse.user));
//       localStorage.setItem("accessToken", loginResponse.access);
//       localStorage.setItem("refreshToken", loginResponse.refresh);

//       setSnackbarMessage("Login successful!");
//       setSnackbarSeverity("success");
//       setSnackbarOpen(true);

//       // Navigate to dashboard after delay
//       setTimeout(() => {
//         navigate("/dashboard");
//       }, 1000);

//     } catch (error) {
//       console.error("Login error:", error);
//       setSnackbarMessage(error.message || "Login failed. Please try again.");
//       setSnackbarSeverity("error");
//       setSnackbarOpen(true);
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Load remembered email on component mount
//   useEffect(() => {
//     const rememberedEmail = localStorage.getItem("rememberedEmail");
//     if (rememberedEmail) {
//       setEmail(rememberedEmail);
//       setRememberMe(true);
//     }

//     // Clear any existing tokens on mount
//     localStorage.removeItem("accessToken");
//     localStorage.removeItem("refreshToken");
//   }, []);

//   return (
//     <div className="log-in">
//       <div>
//         <a href="https://itservicedeskafrica.com/" target="_blank" rel="noreferrer">
//           <div className="ITSA-logo"></div>
//         </a>
//         <img className="process-cuate" src={image} alt="Process Cuate" />
//       </div>
//       <div>
//         <div className="div">
//           <div className="frame">
//             <div className="text-wrapper">Welcome Back</div>
//             <div className="text-wrapper-2">Log into your account</div>
//           </div>
//           <div className="frame-2">
//             <form onSubmit={handleSubmit}>
//               <div className="frame-3">
//                 <div className="frame-wrapper">
//                   <div className="frame-4">
//                     <label className="label" htmlFor="input-email">
//                       Email
//                     </label>
//                     <div className="frame-5">
//                       <input
//                         className="input-text"
//                         placeholder="Enter your Email"
//                         type="email"
//                         id="input-email"
//                         value={email}
//                         onChange={(e) => setEmail(e.target.value)}
//                         required
//                         disabled={loading}
//                       />
//                     </div>
//                   </div>
//                 </div>
//                 <div className="frame-wrapper">
//                   <div className="frame-4">
//                     <label className="label" htmlFor="input-password">
//                       Password
//                     </label>
//                     <div className="frame-5">
//                       <input
//                         className="input-text"
//                         placeholder="Enter your password"
//                         type={eyeOff ? "password" : "text"}
//                         id="input-password"
//                         value={password}
//                         onChange={(e) => setPassword(e.target.value)}
//                         required
//                         disabled={loading}
//                       />
//                       <IconButton
//                         onClick={togglePassword}
//                         disabled={loading}
//                         style={{
//                           position: "absolute",
//                           right: "35px",
//                           top: "49%",
//                           transform: "translateY(-100%)",
//                         }}
//                       >
//                         {eyeOff ? <VisibilityOff /> : <Visibility />}
//                       </IconButton>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="frame-6">
//                   <input
//                     type="checkbox"
//                     id="remember-me"
//                     className="checkbox"
//                     checked={rememberMe}
//                     onChange={(e) => setRememberMe(e.target.checked)}
//                     disabled={loading}
//                   />
//                   <label htmlFor="remember-me" className="text-wrapper-3">
//                     Remember me?
//                   </label>
//                 </div>
//               </div>
//               <button className="button" type="submit" disabled={loading}>
//                 <div className="button-sample">
//                   {loading ? "Logging in..." : "Log in"}
//                 </div>
//               </button>
//             </form>
//           </div>
//           <div className="overlap-group">
//             <div className="social-login">
//               <div className="sign-in-with-socials">
//                 <div className="text-wrapper-4">or</div>
//               </div>
//               <div className="frame-7">
//                 <img src={image1} alt="Google" className="google-png" />
//                 <div className="text-wrapper-5">Login with Google</div>
//               </div>
//               <p className="don-t-have-an">
//                 <span>Don't&nbsp;&nbsp;have an account?</span>
//                 <a href="/SignUp" className="text-wrapper-7">
//                   Create account
//                 </a>
//               </p>
//             </div>
//             <a href="/ResetPassword" className="text-wrapper-8">
//               Forgot Password?
//             </a>
//           </div>
//         </div>
//       </div>
//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={6000}
//         onClose={handleSnackbarClose}
//         anchorOrigin={{ vertical: "top", horizontal: "center" }}
//       >
//         <Alert
//           onClose={handleSnackbarClose}
//           severity={snackbarSeverity}
//           sx={{ width: "100%" }}
//         >
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>
//     </div>
//   );
// }