import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CircleIcon from "@mui/icons-material/Circle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MessageIcon from "@mui/icons-material/Message";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from '@mui/icons-material/Menu';
import { useMediaQuery } from '@mui/material';
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import logo from "../../img/logo.png";
import "./topbar.css";
import { useSidebar } from '../sidebar/SidebarContext';

// API calling functions
const fetchData = async (endpoint) => {
  const response = await fetch(`/api/${endpoint}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
    }
  });

  const data = await response.json();
  
  if (data.status !== 200) {
    throw new Error(data.message || `Failed to fetch ${endpoint}`);
  }

  return data;
};

export default function Topbar() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:768px)');
  const [input, setInput] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [messages, setMessages] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMessagesOpen, setIsMessagesOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [userProfile, setUserProfile] = useState({ technicianName: '', avatar: '../assets/baby.png' });
  const [loading, setLoading] = useState(true);
  const { isSidebarOpen, setIsSidebarOpen } = useSidebar();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error"
  });

  const toggleSidebar = () => {
    setIsSidebarOpen(prev => !prev);
  };

  const fetchUserData = async () => {
    try {
      const [messagesData, notificationsData, profileData] = await Promise.all([
        fetchData('messages'),
        fetchData('notifications'),
        fetchData('gettechnicianprofile')
      ]);

      setMessages(messagesData.data?.messages || []);
      setNotifications(notificationsData.data?.notifications || []);
      setUserProfile({
        technicianName: profileData.data?.technicianName || 'User',
        avatar: profileData.data?.avatar || '../assets/baby.png'
      });
    } catch (error) {
      console.error("Fetch error:", error);
      setSnackbar({
        open: true,
        message: error.message || 'Failed to fetch user data',
        severity: 'error'
      });

      // If unauthorized, redirect to login
      if (error.message?.toLowerCase().includes('unauthorized')) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        navigate('/');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Check for token before fetching
    const token = localStorage.getItem('accessToken');
    if (!token) {
      navigate('/');
      return;
    }

    fetchUserData();
    const refreshInterval = setInterval(fetchUserData, 5 * 60 * 1000);
    return () => clearInterval(refreshInterval);
  }, [navigate]);

  const handleSearch = async (value) => {
    setInput(value);
    if (!value.trim()) return;

    try {
      const response = await fetchData(`searchdevice?query=${encodeURIComponent(value)}`);
      // Handle search results as needed
      console.log(response.data);
    } catch (error) {
      setSnackbar({
        open: true,
        message: error.message || 'Search failed',
        severity: 'error'
      });
    }
  };

  const handleLogout = async () => {
    try {
      const response = await fetch('/api/logout/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
        }
      });

      const data = await response.json();
      
      if (data.status !== 200) {
        throw new Error(data.message || 'Logout failed');
      }

      // Clear all tokens
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('userEmail');
      
      setSnackbar({
        open: true,
        message: "Logged out successfully",
        severity: "success"
      });

      // Redirect after short delay
      setTimeout(() => {
        navigate("/");
      }, 1500);
    } catch (error) {
      console.error("Logout error:", error);
      setSnackbar({
        open: true,
        message: error.message || 'Logout failed',
        severity: 'error'
      });
    }
  };

  // Toggle functions remain the same
  const toggleDropdown = () => setIsDropdownOpen(prev => !prev);
  const toggleMessages = () => setIsMessagesOpen(prev => !prev);
  const toggleNotifications = () => setIsNotificationsOpen(prev => !prev);

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  return (
    <div className="topbar">
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      <div className="topbarwrapper">
        {isMobile && (
          <MenuIcon
            className="menuButton"
            onClick={toggleSidebar}
            style={{ 
              display: isSidebarOpen ? 'none' : 'block',
              marginRight: '10px',
              cursor: 'pointer'
            }}
          />
        )}
        
        <div className="topLeft">
          <img src={logo} alt="Logo" className="logo" />
        </div>

        <div className="search-bar-container">
          <div className="input-wrapper">
            <SearchIcon className="search-icon" />
            <input
              type="text"
              placeholder="Search for students by ID or username."
              value={input}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
        </div>

        <div className="topRight">
          <div className="topbarIconcontainer" onClick={toggleMessages}>
            <MessageIcon />
            <span className="topIconBag">
              {loading ? "..." : messages.length}
            </span>
            {isMessagesOpen && (
              <div className="dropdown">
                {messages.length > 0 ? (
                  messages.map((msg) => (
                    <div key={msg.id} className="dropdownItem">{msg.text}</div>
                  ))
                ) : (
                  <div className="dropdownItem">No messages</div>
                )}
              </div>
            )}
          </div>

          <div className="topbarIconcontainer" onClick={toggleNotifications}>
            <NotificationsIcon />
            <span className="topIconBag">
              {loading ? "..." : notifications.length}
            </span>
            {isNotificationsOpen && (
              <div className="dropdown">
                {notifications.length > 0 ? (
                  notifications.map((notif) => (
                    <div key={notif.id} className="dropdownItem">{notif.text}</div>
                  ))
                ) : (
                  <div className="dropdownItem">No notifications</div>
                )}
              </div>
            )}
          </div>

          <div className="rightRight" onClick={toggleDropdown}>
            <h3>{loading ? "..." : userProfile.technicianName}</h3>
            <img src={userProfile.avatar} alt="Profile" className="topAvatar" />
            <ArrowDropDownIcon className="downArrow" />
            {isDropdownOpen && (
              <div className="dropdown">
                <div className="dropdownItem">Profile</div>
                <div className="dropdownItem">Settings</div>
                <div className="dropdownItem" onClick={handleLogout}>Logout</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}